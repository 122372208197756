import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en','de','br','es','ru'],
        fallbackLng: 'ru',
        lng: 'ru',
        ns: ['errors','translation'],
        defaultNS: 'translation',
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
            queryStringParams: { nocache: new Date().getTime() },
        },
        react: {
            useSuspense: false,
        },
        debug: true,

    });

i18n.on('initialized', (options) => {
    console.log('i18next initialized:', options);
});

i18n.on('failedLoading', (lng, ns, msg) => {
    console.error(`Failed to load namespace "${ns}" for language "${lng}":`, msg);
});
i18n.on('loaded', (loaded) => {
    console.log('Translations loaded:', loaded);
});

i18n.changeLanguage('ru').then(() => {
    console.log('Language changed to:', i18n.language);
    console.log('Resources:', i18n.store.data);
});

export default i18n;
