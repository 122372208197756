import * as Sentry from "@sentry/react";
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {YMInitializer} from "react-yandex-metrika";
import './i18n'
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Sentry.init({
    dsn: "https://7afd1bc4b3f09259e1b061c04fe97c1c@sentry.kotelnikoff.dev/10",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration(),

        Sentry.feedbackIntegration({
            colorScheme: "system",
            language: "ru-RU",
            triggerLabel:'Сообщить об ошибке',
            formTitle:'Ошибка',
            submitButtonLabel:'Отправить отчет',
            cancelButtonLabel:'Отмена',
            confirmButtonLabel:'Подтвердить',
            addScreenshotButtonLabel:'Добавить скриншот',
            removeScreenshotButtonLabel:'Удалить скриншот',
            nameLabel:'Как к вам обращаться?',
            namePlaceholder:'Имя',
            emailLabel:'Ваш Email',
            emailPlaceholder:'Email',
            isRequiredLabel:'(Обязательно)',
            messageLabel:'Что случилось?',
            messagePlaceholder:'Опишите ошибку',
            successMessageText:'Спасибо за обратную связь!'
        }),
        Sentry.httpClientIntegration(),
        Sentry.httpContextIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["localhost:3000", /^https:\/\/api.chatboost\.space/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});


root.render(
  <React.StrictMode>
      <YMInitializer accounts={[98204824]} />
      <App/>
  </React.StrictMode>
);