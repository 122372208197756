import React from 'react';
import MainMenu from "../../components/Main/MainMenu";
import Header from "../../components/Main/Header";
import Stack from "@mui/material/Stack";
import {Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {useAppStorage} from "../../storage/AppStorage";
import IconButton from "@mui/material/IconButton";
import {ContentCopy} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {AppServices} from "../../api/services/AppServices";


const SettingsPage = () => {
    const {token, portalId} = useAppStorage()
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const lags=[
        {
            name:'English',
            code:'en'
        },
        {
            name:'Русский',
            code:'ru'
        },
        {
            name:'Deutsch',
            code:'de'
        },
    ]

    const handleClose = (value: string) => {
        setOpen(false);
    };

    const changeLanguage = (lng: string): void => {
        i18n.changeLanguage(lng).catch((error) => {
            console.error("Failed to change language", error);
        });
        setOpen(false);
    };

    const settings = [
        {
            title: t('token_copy'),
            value: token,
            description: t('copy_text')
        },
        {
            title: t('portal_id_title'),
            value: portalId,
            description: portalId
        },
    ]
    const handleCopy = (textToCopy: string) => {
        console.log(textToCopy);
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert('Скопированно');
            })
            .catch(err => {
                console.error('Ошибка: ', err);
            });
    };

    const reinstallApp =async ()=>{
        try{
            const res=await AppServices.reinstallChatApp()
            alert('Приложение переустановлено. Обновите страницу')
        }catch(err){
            console.error('Ошибка: ', err);
            alert(err);
        }
    }

    return (
        <MainMenu>
            <Stack
                spacing={2}
                sx={{
                    mx: 3,
                    pb: 10,
                    mt: {xs: 8, md: 0},
                }}
            >
                <Header>

                </Header>
                <List>
                    {
                        settings.map((item, index) => (
                            <ListItem
                                key={`settings-${index}`}
                                secondaryAction={
                                    <IconButton
                                        onClick={()=>handleCopy(item.value??'Error')}
                                        edge="end" aria-label="comments">
                                        <ContentCopy />
                                    </IconButton>
                            }
                            >
                                <ListItemText
                                    primary={item.title}
                                    secondary={item.description}
                                >
                                </ListItemText>
                            </ListItem>))
                    }
                    <ListItem
                        onClick={() => {
                            setOpen(true);
                        }}
                        key={`settings-lang`}

                    >
                        <ListItemText
                            primary={t('lang_settings')}
                            secondary={i18n.language}
                        >
                        </ListItemText>
                    </ListItem>

                    <ListItem
                        onClick={() => {
                            reinstallApp()
                        }}
                        key={`reinstall-app`}

                    >
                        <ListItemText
                            primary={t('reinstall_app_title')}
                            secondary={t('reinstall_app_description')}
                        >
                        </ListItemText>
                    </ListItem>
                </List>
            </Stack>

            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>{t('select_lag')}</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {lags.map((lng,index) => (
                        <ListItem disableGutters key={lng.code}>
                            <ListItemButton onClick={() => changeLanguage(lng.code)}>
                                <ListItemText primary={lng.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </MainMenu>
    );
};

export default SettingsPage;